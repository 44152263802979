import React from 'react'
import { useParams, Link } from 'react-router-dom'
import { Layout, Table, Breadcrumb, Space, Card, Button } from 'antd'
import { gql, useQuery } from '@apollo/client'
import _groupBy from 'lodash/groupBy'
import moment from 'moment'
import _get from 'lodash/get'
import _unionBy from 'lodash/unionBy'
import _reverse from 'lodash/reverse'
import _sortBy from 'lodash/sortBy'
import _last from 'lodash/last'
import _orderBy from 'lodash/orderBy'
import _findIndex from 'lodash/findIndex'
import _intersectionBy from 'lodash/intersectionBy'
// import last from 'lodash/last'
import routeData from '../data/route.json'
import cutoff from '../data/cutoff.json'

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const { Search } = Input

const GET_EVENT_AND_CHECKPOINT = gql`
  query getEventAndCheckpoint($eventId: MongoID!, $race: String!){
    eventById(_id: $eventId) {
      _id
      name
      slug
      organizId
      startTime
      endTime
      haveChipTime
      checkpoints {
        distance
        position
        cutOffTime
        name
      }
    }
    checkpointByEventIdAndRace(eventId: $eventId, race: $race) {
      bib
      position
      time
      slug
      userId
      _id
    	runner {
        name
        _id
      }
    }
  }
`

function calcTime(before, current){
  const curTime = moment(current)
  const beforeTime = moment(before)
  const diff = curTime.diff(beforeTime)
  console.log(diff, 'diff');
  const duration = moment.duration(diff)
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
}

function calcPace(time, distance) {
  const minutes = (time.hour()*60) + time.minute() + (time.second() / 60)
  const paceResult = minutes / distance
  const paceTime = moment.utc().startOf('day').add({ minutes: paceResult }).format('mm:ss')
  return paceTime
}

function buildData(checkpoints, checkpointsData) {
  const results = []
  // const times = []
  for (const [key, value] of Object.entries(checkpointsData)) {
    if (key !== 'null') {
      const result = {
        bib: key
      }
      const checkpointsInfo = checkpoints.map(cp => cp)
      console.log(checkpointsInfo);
      _reverse(value)
      const data = _unionBy(value, ({ position }) => position)
      const sortedData = _sortBy(data, e => e.position)
      let timeArr = []
      sortedData.forEach(data => {
        // const splitTime = calcTime(defaultTime, data.time)
        result[data.position] = moment(data.time).format('HH:mm:ss')
        result.name = _get(data, 'runner.name', '-')
      })
      timeArr = sortedData.sort(function (left, right) {
        return moment.utc(left.time).diff(moment.utc(right.time))
      });
     console.log(timeArr, 'timeArr');
      checkpointsInfo.forEach(({ position, distance }, index) => {  // รวมเวลาทั้งหมดไว้ใน array
        if(!result[position] || moment(result[position]).isAfter(moment(result[position+1])) ) {
          result.time = 'DNF'
        }
        if (!result[position]) {
          result.gunTime = 'DNF'
          result.chipTime = 'DNF'
          result.avgPace = '-'
          return
        }
        if(position !== 0){
          const timeIndex = _findIndex(timeArr, function(t) { return t.position === position })
          console.log(timeIndex, 'tindex');
          // const start = moment(result[position - 1], 'HH:mm:ss')
          const start = moment(_get(timeArr[timeIndex - 1], 'time'), 'HH:mm:ss')
          console.log(start);
          const end = moment(result[position], 'HH:mm:ss')
          const diff = moment.utc(moment.duration(end.diff(start)).asMilliseconds())
          result[`pace${position}`] = diff.isValid() ? diff.format('mm:ss') : '-'
        }
        
      })
      console.log(checkpointsInfo, 'checkpointsInfo2');
      if (result.gunTime !== 'DNF') {  // คำนวนเวลาทั้งหมด
        console.log(result, 'result');
        const start = moment(result[checkpointsInfo[0].position], 'HH:mm:ss')
        console.log(moment('10:48:41', 'HH:mm:ss'));
        console.log(start, 'startall');
        const { position, distance } = _last(checkpointsInfo)
        const lastData = sortedData.find(d => d.position === position)
        // const totalTime = times.slice(1).reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(times[0]))
        // const gunTime = moment.utc(totalTime.asMilliseconds()).format("HH:mm:ss")
        const chipTime = calcTime(start, lastData.time)
        result.avgPace = calcPace(moment(chipTime, 'HH:mm:ss'), distance)
        result.gunTime = calcTime(start, lastData.time)
        result.chipTime = chipTime //calcTime(defaultTime, lastData.time)
        result.time = result.time === 'DNF' ? 'DNF': calcTime(start, lastData.time)
      } 
      // console.log(result);
      results.push(result)
    }
  }

  return results
}

function sortRank(data, checkpointsInfo, race) {
  console.log(data.time , 'timetime');
  const dnfData = data.filter(({ gunTime }) => gunTime === 'DNF')
  const finishData = data.filter(({ gunTime }) => gunTime !== 'DNF')
  console.log(finishData, 'finishData');
  // let lastcp = ''
  let lastpos = 0
  checkpointsInfo.forEach((cp, i) => {
    if(finishData[`${i + 1}`]){
      // lastcp = finishData[`${i + 1}`]
      lastpos = i+1
    }
  })
  console.log(lastpos, 'lastpos');
  // const orderedData = _orderBy(finishData, o => moment(moment(data.time, 'HH:mm:ss')), ['asc'])
  let orderedData = _orderBy(finishData, o => moment(_get(o, `${lastpos}`), 'HH:mm:ss'), ['asc'])
  if(race === '42h' || race === '60'){
    orderedData = finishData.sort((a,b)=> moment(a.time, 'HH:mm:ss').diff(moment(b.time, 'HH:mm:ss')))
  } 
  
  // const orderedData = finishData.sort((a,b)=> moment(a.time, 'HH:mm:ss').diff(moment(b.time, 'HH:mm:ss')))
  const orderedDataWithPos = orderedData.map((data, index) => ({...data, pos: index+1}))
  console.log('orderedDataWithPos', orderedDataWithPos)
  return orderedDataWithPos.concat(dnfData)
}

function ListCheckpoint() {
  const { eventId, organizId } = useParams()
  // const location = useLocation()
  // const history = useHistory()
  // const [race, setRace] = useState('102 KM')
  // let race = '102 KM'
  // // const { s: search } = location.query
  
  // console.log(location.search, 'location');
  // const searchSplit = location.search.split('&')
  // // console.log(searchSplit);
  // const s = searchSplit.findIndex((e)=> {return e.startsWith('?s')})
  // const r = searchSplit.findIndex((e)=> {return e.startsWith('race')})
  // // console.log(s, 's');
  // let search = ''
  // if(s !== -1){
  //   search = searchSplit[s].substring(3)
  // }
  // if(r !== -1){
  //   console.log(searchSplit[r].substring(5));
  //   // setRace('102 KM')
  //   if(searchSplit[r].substring(5) !== '42h'){
  //     race = `${searchSplit[r].substring(5)} K`
  //   } else {
  //     race = `42 K Highlight `
  //   }
    
  // }
  // console.log(search);

  const { data, loading } = useQuery(GET_EVENT_AND_CHECKPOINT, { variables: { eventId, race: 'มาราธอน รัน 42 K' }})

  if (loading) return <div>loading...</div>
  const { eventById: event , checkpointByEventIdAndRace: checkpoints } = data
  const checkpointsInfo = event.checkpoints.map(cp => cp)
  // checkpointsInfo.pop()
  if (event.haveChipTime) {
    checkpointsInfo.shift()
  }
  // console.log(checkpointsInfo, 'checkpointsInfo');
  console.log(routeData, 'routeData');

  const intersecCP = _intersectionBy(checkpointsInfo, _get(routeData, `42hk`),'position')
  console.log(intersecCP, 'intersecCP');
  
  let columnsCheckpoint = intersecCP.map((cp, index) => ({
    title: `เช็คพอยท์ ${cp.position} (${cp.distance})`,
    dataIndex: cp.position,
    key: cp.position,
    render: (time, record) => {
      // console.log(record);
      if (!time) {
        return '-'
      }
      const place = record[`pace${cp.position}`]
      return `${time} \n (${place})`
    }
  }))
  // console.log(columnsCheckpoint, 'cp');
  // columnsCheckpoint = [columnsCheckpoint[columnsCheckpoint.length - 1]]
  console.log(columnsCheckpoint, 'columnsCheckpoint');

  const columns = [
    {
      title: 'ลำดับ',
      dataIndex: 'pos',
      key: '_id',
      render: pos => pos || '-'
    },
    {
      title: 'เลขบิบ',
      dataIndex: 'bib',
      key: 'bib'
    },
    {
      title: 'ชื่อ-สกุล',
      dataIndex: 'name',
      key: 'name',
      render: (item, record) => {
        return <Link to={`/participant/${organizId}/${eventId}/${record.bib}`}><span>{item}</span></Link>
      }
    },
    ...columnsCheckpoint,
    {
      title: 'Last Checkpoint',
      dataIndex: 'bib',
      key: 'lcp',
      render: (item, record, index) => {
        let lastcp = ''
        let cpname = ''
        checkpointsInfo.forEach((cp, i) => {
          // console.log(cp);
          if(record[`${i + 1}`]){
            // console.log(record[`${i + 1}`]);
            lastcp = record[`${i + 1}`]
            cpname = cp.name
          }
          // return lastcp
        })
        // console.log(lastcp);
        // const time = record[`${index + 1}`]
        return `${cpname}: ${lastcp}`
      }
    },
    // {
    //   title: 'Gun Time',
    //   dataIndex: 'gunTime',
    //   key: 'gunTime'
    // },
    // {
    //   title: 'AVG Pace',
    //   dataIndex: 'avgPace',
    //   key: 'avgPace'
    // },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (time, record) => {
        if(record.bib === '73' ) return <span>DNF</span>
        console.log(time, 'timetime');
        if(time === 'DNF') return <span>DNF</span>
        // console.log(`${searchSplit[r]?.substring(5)}k`, 'race');
        const startTime = "2024-02-03T21:00:00.483Z"
        console.log(startTime, 'startTime');
        let lastcp = ''
        checkpointsInfo.forEach((cp, i) => {
          if(record[`${i + 1}`]){
            lastcp = record[`${i + 1}`]
          }
        })

        const curT =moment(lastcp, 'HH:mm:ss')
        const curTime = moment(curT, 'HH:mm:ss')
        console.log(curTime,' curTime');
        const beforeTime = moment(startTime) 
        console.log(beforeTime,' beforeTime');
        const diff = curTime.diff(beforeTime)
        const duration = moment.duration(diff)
        console.log(duration, 'duration');

        console.log(duration.asHours(), 'duration');
        console.log(_get(cutoff, `42hk`, 0), 'cutoff');
        // if( (duration.asHours() > _get(cutoff, `${searchSplit[r]?.substring(5)}k`, 0)) || duration.asHours() < 0 ) return <span>DNF</span> //cutoff
        // const total = calcTime(event.startTime ,moment(lastcp, 'HH:mm:ss'))
        // console.log(total, 'total');
        return <span>{moment.utc(duration.asMilliseconds()).format('HH:mm:ss')}</span>
      }
    },
  ]

  console.log(checkpoints, 'checkpoints');
  console.log(intersecCP, 'intersec');
  
  const groupedCheckpoints = _groupBy(checkpoints, cp => cp.bib)
  let dataSource = buildData(intersecCP, groupedCheckpoints, event.startTime)
  // console.log(dataSource, 'datas');
  dataSource = sortRank(dataSource, intersecCP, )
  // console.log(dataSource);
  // if(!loading && dataSource){
  //     dataSource = dataSource.filter((value) => {
  //     const data = !![value.bib, value.name].find(
  //       (k) => k.toLowerCase().replace(/\s/g, '').search(search.toLowerCase()) !== -1,
  //     )
  //     return data
  //   })
  // }

  // const handleSearch = value => {
  //   // console.log(typeof searchSplit[r]?.substring(5))
  //   // if(searchSplit[r]?.substring(5) === 'undefined'){
  //     history.push(`?s=${value}&race=42h`)
  //   // } else {
  //   //   history.push(`?s=${value}&race=${searchSplit[r]?.substring(5)}`)
  //   // }
    
  // }

  const dataExport = dataSource.map((item, index) => {
    // if(item.bib === '73' ) return <span>DNF</span>
    // if(item.time === 'DNF') return <span>DNF</span>
    const startTime = "2024-02-03T21:00:00.483Z"
    // console.log(startTime, 'startTime');
    let lastcp = ''
    checkpointsInfo.forEach((cp, i) => {
      if(_get(item, `${i + 1}`)){
        lastcp = _get(item, `${i + 1}`)
      }
    })

    const curT =moment(lastcp, 'HH:mm:ss')
    const curTime = moment(curT, 'HH:mm:ss')
    const beforeTime = moment(startTime) 
    const diff = curTime.diff(beforeTime)
    const duration = moment.duration(diff)
    // return <span>{moment.utc(duration.asMilliseconds()).format('HH:mm:ss')}</span>
    let time = 'DNF'
    if(item.bib === '73' || item.time === 'DNF') {
      time = 'DNF'
    } else {
      time =  moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
    }
   
    return {
      pos: item.pos,
      bib: item.bib,
      name: item.name,
      time: time
    }
  })
  console.log(dataExport, 'dataExport');
  return (
    <Layout>
      <Layout.Content>
        <Space style={{ width: '100%' }} direction="vertical">
          <h1>Start: {moment(event.startTime).format('HH:mm:ss')}</h1>
          <ExcelFile element={<Button>Export</Button>} filename={'42'}>
            <ExcelSheet data={dataExport} name="results">
                <ExcelColumn label="ลำดับ" value="pos"/>
                <ExcelColumn label="เลขบิบ" value="bib"/>
                <ExcelColumn label="ชื่อ-สกุล" value="name"/>
                <ExcelColumn label="Time" value="time"/>
            </ExcelSheet>
          </ExcelFile>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/event/${organizId}`}>การแข่งขันทั้งหมด</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {event.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              42
            </Breadcrumb.Item>
          </Breadcrumb>
          {/* <Search placeholder="ค้นหา" style={{ width: 200 }} defaultValue={search} onSearch={handleSearch} /> */}
          <Card style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px', borderRadius: '8px' }} >
           <Table scroll={{ x: true }} rowKey={record => record.bib} columns={columns} dataSource={dataSource} pagination={{ pageSize: 100 }} />
          </Card>
        </Space>
      </Layout.Content>
    </Layout>
  )
}

export default ListCheckpoint