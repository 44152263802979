import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Layout, Card, Row, Col, Typography, Avatar, Button, Input} from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client'
import racepackClient from '../apollo-client/racepack'
import _groupBy from 'lodash/groupBy'
import moment from 'moment'
import _get from 'lodash/get'
import _unionBy from 'lodash/unionBy'
import _reverse from 'lodash/reverse'
import _sortBy from 'lodash/sortBy'
import _last from 'lodash/last'
import _orderBy from 'lodash/orderBy'
import _findIndex from 'lodash/findIndex'
import _find from 'lodash/find'

const GET_EVENT_AND_CHECKPOINT = gql`
  query getEventAndCheckpoint($eventId: MongoID!, $bib: Int!){
    eventById(_id: $eventId) {
      _id
      name
      slug
      organizId
      startTime
      endTime
      haveChipTime
      checkpoints {
        name
        distance
        position
        cutOffTime
      }
    }
    checkpointByRunner(eventId: $eventId, bib: $bib) {
      bib
      position
      time
      slug
      userId
      _id
    	runner {
        name
        _id
      }
    }
  }
`
const GET_PARTICIPANT_RACEPACK = gql`
query($eventId: String, $bib: String){
  activities(filter: {status: "faceChecking", eventId: $eventId, bib: $bib}){
    _id
    photo
  }
}
`

const GET_TIME = gql`
  query getTime($slug: String!, $bib: String!) {
    getTime(slug: $slug, bib: $bib){
      name
      bib
      time
    }
  }
`

function calcTime(before, current){
  const curTime = moment(current)
  const beforeTime = moment(before)
  const diff = curTime.diff(beforeTime)
  const duration = moment.duration(diff)
  return moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
}

function calcPace(time, distance) {
  const minutes = (time.hour()*60) + time.minute() + (time.second() / 60)
  const paceResult = minutes / distance
  const paceTime = moment.utc().startOf('day').add({ minutes: paceResult }).format('mm:ss')
  return paceTime
}

function buildData({ startTime, haveChipTime, checkpoints }, checkpointsData) {
  const results = []
  // const times = []
  for (const [key, value] of Object.entries(checkpointsData)) {
    if (key !== 'null') {
      const result = {
        bib: key
      }
      const checkpointsInfo = checkpoints.map(cp => cp)
      // console.log(checkpointsInfo);
      _reverse(value)
      const data = _unionBy(value, ({ position }) => position)
      const sortedData = _sortBy(data, e => e.position)
      let timeArr = []
      sortedData.forEach(data => {
        // const splitTime = calcTime(defaultTime, data.time)
        result[data.position] = moment(data.time).format('HH:mm:ss')
        result.name = _get(data, 'runner.name', '-')
      })
      timeArr = sortedData.sort(function (left, right) {
        return moment.utc(left.time).diff(moment.utc(right.time))
      });
     
      checkpointsInfo.forEach(({ position, distance }, index) => {  // รวมเวลาทั้งหมดไว้ใน array
        if(!result[position] || moment(result[position], 'HH:mm:ss').isAfter(moment(result[position+1], 'HH:mm:ss')) ) {
          result.time = 'DNF'
        }
        if (!result[position]) {
          result.gunTime = 'DNF'
          result.chipTime = 'DNF'
          result.avgPace = '-'
          return
        }
        if(position !== 0){
          const timeIndex = _findIndex(timeArr, function(t) { return t.position === position })
          // const start = moment(result[position - 1], 'HH:mm:ss')
          const start = moment(_get(timeArr[timeIndex - 1], 'time'), 'HH:mm:ss')
          // console.log(start);
          const end = moment(result[position], 'HH:mm:ss')
          const diff = moment.utc(moment.duration(end.diff(start)).asMilliseconds())
          result[`pace${position}`] = diff.isValid() ? diff.format('mm:ss') : '-'
        }
        
      })
      if (result.gunTime !== 'DNF') {  // คำนวนเวลาทั้งหมด
        // console.log(result);
        const start = moment(result[1], 'HH:mm:ss')
        // console.log(start);
        const { position, distance } = _last(checkpointsInfo)
        const lastData = sortedData.find(d => d.position === position)
        // const totalTime = times.slice(1).reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(times[0]))
        // const gunTime = moment.utc(totalTime.asMilliseconds()).format("HH:mm:ss")
        const chipTime = calcTime(start, lastData.time)
        result.avgPace = calcPace(moment(chipTime, 'HH:mm:ss'), distance)
        result.gunTime = calcTime(start, lastData.time)
        result.chipTime = chipTime //calcTime(defaultTime, lastData.time)
        result.time = result.time === 'DNF' ? 'DNF': calcTime(start, lastData.time)
      } 
      // console.log(result);
      results.push(result)
    }
  }

  return results
}

function sortRank(data) {
  const dnfData = data.filter(({ gunTime }) => gunTime === 'DNF')
  const finishData = data.filter(({ gunTime }) => gunTime !== 'DNF')
  const orderedData = _orderBy(finishData, o => moment(o.gunTime, 'HH:mm:ss'), ['asc'])
  const orderedDataWithPos = orderedData.map((data, index) => ({...data, pos: index+1}))
  // console.log('orderedDataWithPos', orderedDataWithPos)
  return orderedDataWithPos.concat(dnfData)
}

function ListCheckpoint() {
  const [search, setSearch] = useState('')
  const history = useHistory()
  const { eventId, bib } = useParams()
  const { data, loading } = useQuery(GET_EVENT_AND_CHECKPOINT, { variables: { eventId, bib: parseInt(bib) }})
  const participantImage = useQuery(GET_PARTICIPANT_RACEPACK, 
    {
      client: racepackClient,
      variables: { eventId: "67988dcd3c3fb60014770cc8", bib }
    }
  )
  const { data: filnalTime } = useQuery(GET_TIME, { variables: { slug: 'sp100ep3', bib: bib } })

  if (loading || participantImage.loading) return <div>loading...</div>
  const { eventById: event , checkpointByRunner: checkpoints } = data
  // console.log(checkpoints,'chec');
  const checkpointsInfo = event.checkpoints.map(cp => cp)
  // checkpointsInfo.pop()
  if (event.haveChipTime) {
    checkpointsInfo.shift()
  }
  // console.log(checkpointsInfo);
  // console.log(checkpointsInfo,'checkpointsInfo');

  const obj = participantImage.data.activities[participantImage.data.activities.length - 1]
  
  const cp = checkpoints.filter((e)=>e.bib === parseInt(bib))
  const groupedCheckpoints = _groupBy(cp, cp => cp.bib)
  let dataSource = buildData(event, groupedCheckpoints)
  dataSource = sortRank(dataSource)
  console.log(checkpointsInfo,'checkpointsInfo');
  console.log(cp,'cp');

  const getCheckpointName = position => {
    const res = _find(event.checkpoints, function(o){ return o.position === parseInt(position)})
    return res.name
  }
  const unionPosition = _unionBy(cp, checkpointsInfo,'position')
  console.log(unionPosition,'unionPosition');
  const { Title } = Typography;


  const onSubmit = () => {
    if(search !== ''){
      return history.push(`/participant/65938872657959f307e0b169/659392d94fe5ca78af73330b/${search}`)
    }
  }


  // console.log(dataSource,'dataSource');
  return (
    <Layout >
      <Layout.Content>
        <Card style={{border: '1px solid rgb(235, 235, 235)' , boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px', borderRadius: '8px', fontFamily:'Sukhumvit Set' }} 
          title = {<div style={{display : 'flex'}}>{obj && obj.photo ? <Avatar size={240} src={obj.photo} /> :  <Avatar size={240} icon={<UserOutlined />} /> }<Title style={{ paddingLeft : '40px',paddingTop : '60px'}} level={2}><p>เลขบิบ : {dataSource[0]?.bib}</p> <p>ชื่อ : {dataSource[0]?.name}</p><p>{filnalTime?.getTime?.time && 'เวลารวม : '}{filnalTime?.getTime.time}</p>  </Title></div>} >
        {unionPosition.map((e) =>
          <div >
            <Row gutter={[16,16]} style={{ width: '100%' }} direction="vertical">
              <Col span={8} style={{textAlign:''}}>
                  <p style={{textAlign: 'left', fontSize: '20px', fontFamily:'Sukhumvit Set'}}> CheckPoints : {getCheckpointName(e.position)} </p>
              </Col>
              <Col span={8}>
                  <p style={{textAlign: 'center', fontSize: '20px',fontFamily:'Sukhumvit Set'}}> { e.time && moment(e.time).format('HH:mm:ss')} </p>
              </Col>
            </Row>
          </div>
        )}
        </Card>
        <div style={{position: 'absolute', bottom: 50, right: 100, display: 'flex'}}>
          <Input
           placeholder='bib'
           onChange={e => setSearch(e.target.value)}
           />
          <Button onClick={onSubmit}>
            submit
          </Button>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default ListCheckpoint